import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const Nav = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { markdownKey: { eq: "config" } }) {
        frontmatter {
          menu {
            label
            path
          }
        }
      }
    }
  `)
  return (
    <ul>
      {data.markdownRemark.frontmatter.menu.map((link: any, i: number) => {
        if (/^#/.test(link.path)) {
          return (
            <li key={i} onClick={() => (location.href = '/#pricing')}>
              {link.label}
            </li>
          )
        }
        return (
          <li key={i}>
            <Link to={link.path}>{link.label}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Nav
