import * as React from 'react'
import styled from '@emotion/styled'
import Nav from './navigation'

const Wrapper = styled.div`
  width: 100%;
`

const MobileHeaderContainer = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Hamburger = styled.div`
  display: flex;
  cursor: pointer;
  background: none;
  height: 40px;
  float: right;
  align-items: center;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  text-transform: none;
  background-color: transparent;
  overflow: visible;
`

const HamburgerBox = styled.span`
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1000;
`

const HamburgerInner = styled.div<{ isActive: boolean }>`
  display: block;
  top: 0;
  margin-top: 8px;
  &,
  &::before,
  &::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }

  &::after {
    top: 20px;
    bottom: -10px;
  }

  ${({ isActive }) =>
    isActive &&
    `
    transform: translate3d(0, 10px, 0) rotate(45deg);
    &::before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0; 
    }
    &::after {
      transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
  `}
`

const Background = styled.div<{ isActive: boolean }>`
  background-image: radial-gradient(
    ${({ theme }) => `${theme.colors.primary}, ${theme.colors.secondary}`}
  );
  transform: scale(1);
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 60;
  border-radius: 50%;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-property: transform;
  ${({ isActive }) =>
    isActive &&
    `
    transform: scale(120);
  `}
`

const Menu = styled.div<{ isActive: boolean }>`
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 0;
  z-index: 1;

  transition-property: opacity, min-height;
  transition-duration: 0.2s;
  transition-timing-function: linear;

  & ul {
    z-index: 1000;
    display: none;
    ${({ isActive }) =>
      isActive &&
      `
      display: block;
    `}
    & li {
      padding: 10px 0;
      text-align: center;
      & a {
        font-weight: 500;
        font-size: 24px;
        text-decoration: none;
        color: white;
        :hover {
          color: red;
        }
      }
    }
  }
  ${({ isActive }) =>
    isActive &&
    `
    opacity: 1;
    min-height: 100vh;
  `}
`

const Burger: React.SFC<{
  isActive: boolean
  onClick: React.DOMAttributes<HTMLDivElement>['onClick']
}> = props => (
  <Wrapper>
    <Hamburger {...props}>
      <HamburgerBox>
        <HamburgerInner {...props} />
      </HamburgerBox>
    </Hamburger>
    <Menu {...props}>
      <Background {...props} />
      <Nav />
    </Menu>
  </Wrapper>
)

const Mobile: React.SFC = () => {
  const [collapsed, toggleMenu] = React.useState(true)
  return (
    <MobileHeaderContainer>
      <Burger isActive={!collapsed} onClick={() => toggleMenu(!collapsed)} />
    </MobileHeaderContainer>
  )
}

export default Mobile
