import * as React from 'react'
import styled from '@emotion/styled'
import Nav from './navigation'

const NavContainer = styled.nav`
  display: none;
  @media (min-width: 1026px) {
    display: block;
  }
  & ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    float: right;
    & li {
      &::before {
        display: none;
      }
      padding: 20px 15px;
      display: block;
      font-size: 1em;
      font-weight: 500;
      :last-of-type {
        margin-right: 10px;
      }
      & a {
        position: relative;
        text-decoration: none;
        color: inherit;
        &::before {
          transition: opacity 0.2s ease-out;
          opacity: 0;
          content: '';
          position: absolute;
          bottom: -5px;
          left: 0;
          right: 0;
          background-color: white;
          height: 2px;
        }
        :hover {
          &::before {
            opacity: 1;
          }
        }
        &[aria-current='page'] {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
`

export default () => (
  <NavContainer>
    <Nav />
  </NavContainer>
)
