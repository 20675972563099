import * as React from 'react'
import styled from '@emotion/styled'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Box, Flex } from 'rebass'
import { Logo, Section } from 'components/ui'

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
  & > span {
    margin-top: 10px;
    font-size: 14px;
  }
`

const FooterLinks = styled.ul`
  margin-top: 20px;
  & > li {
    height: 28px;
    & > a {
      text-decoration: none;
      color: inherit;
    }
    &:first-of-type {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
`

const RDWErkendWrapper = styled.div`
  width: 90%;
  max-width: 300px;
`

export default () => {
  const { rdwErkend } = useStaticQuery(graphql`
    query {
      rdwErkend: file(name: { eq: "RDW-erkend" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            src
          }
        }
      }
    }
  `)
  return (
    <Section sx={{ flexShrink: 0 }} backgroundColor="dark" color="white">
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        <Box width={[7 / 12, 2 / 12]}>
          <LogoWrapper>
            <Logo />
            <span>Copyright 2020</span>
          </LogoWrapper>
          <RDWErkendWrapper>
            <img
              src={rdwErkend.childImageSharp.fluid.src}
              alt="RDW erkend"
              width="100%"
            />
          </RDWErkendWrapper>
        </Box>
        <Box>
          <FooterLinks>
            <li>Meer</li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/over">Over</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/paul-coenen-69239a162">
                LinkedIn
              </a>
            </li>
          </FooterLinks>
        </Box>
        <Box>
          <FooterLinks>
            <li>Legal</li>
            <li>Voorwaarden</li>
            <li>
              <a
                href="/downloads/Privacy-statement-PC-Automotive-Services.pdf"
                target="_blank"
              >
                Privacy Statement
              </a>
            </li>
          </FooterLinks>
        </Box>
        <Box>
          <FooterLinks>
            <li>Partners</li>
            <li>
              <a href="http://automotivetax.nl">Automotive Tax</a>
            </li>
            <li>
              <a href="http://originelewielen.nl">originelewielen.nl</a>
            </li>
          </FooterLinks>
        </Box>
        <Box>
          <Box mt={[30, 0]}>
            <FooterLinks>
              <li>Keep in touch</li>
              <li>
                <a href="mailto:PC Auto Import <info@pcautoimport.nl>">
                  info@pcautoimport.nl
                </a>
              </li>
              <li>+31 (0) 20 244 1586</li>
              <li />
              <li>Swammerdamstraat 12B</li>
              <li>1091 RT</li>
              <li>Amsterdam</li>
            </FooterLinks>
          </Box>
        </Box>
      </Flex>
    </Section>
  )
}
