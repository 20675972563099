import React from 'react'
import styled from '@emotion/styled'
import Desktop from './desktop'
import Mobile from './mobile'
import { throttle } from 'lodash'
import { Logo } from '../ui'
import useMode from '../../utils/useMode'
import { Box, Flex } from 'rebass'
import { Link } from 'gatsby'

const HeaderSection = styled.header`
  position: relative;
`

const Wrapper = styled(Flex)`
  position: fixed;
  align-items: center;
  width: 100%;
  z-index: 10000;
  color: white;
`

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s ease-out;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: -1;
`

const Header: React.SFC = () => {
  const [mode] = useMode()
  const [isTop, setIsTop] = React.useState(true)
  React.useEffect(() => {
    const isTopHandler = throttle(() => setIsTop(window.scrollY < 100), 100)
    window.addEventListener('scroll', isTopHandler)
    return () => {
      isTopHandler.cancel()
      window.removeEventListener('scroll', isTopHandler)
    }
  })
  return (
    <HeaderSection>
      <Wrapper
        justifyContent="space-between"
        alignItems="center"
        padding={[20, 15]}
      >
        <Background style={{ opacity: mode === 'light' && isTop ? 0 : 1 }} />
        <Box width={[500, 300]}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Desktop />
        <Mobile />
      </Wrapper>
    </HeaderSection>
  )
}
export default Header
