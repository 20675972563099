import React from 'react'
import { Box, Flex } from 'rebass'
import { ThemeContext } from '../Theme'
import Nav from '../components/Navbar'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Layout: React.SFC = ({ children }) => {
  const { site, ogImage } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
      ogImage: file(relativePath: { eq: "OG_IMAGE.png" }) {
        childImageSharp {
          resize(width: 1200, height: 630, cropFocus: CENTER) {
            src
          }
        }
      }
    }
  `)
  return (
    <ThemeContext.Consumer>
      {([mode]) => (
        <>
          <Helmet
            titleTemplate={`%s - ${site.siteMetadata.title}`}
            meta={[
              {
                name: 'description',
                content: site.siteMetadata.description,
              },
              {
                property: 'og:image',
                content: `${process.env.GATSBY_HOST}${ogImage.childImageSharp.resize.src}`,
              },
              {
                property: 'og:description',
                content: site.siteMetadata.description,
              },
              { property: 'og:type', content: 'website' },
              {
                property: 'og:url',
                content: process.env.GATSBY_HOST,
              },
              {
                property: 'og:title',
                content: site.siteMetadata.title,
              },
            ]}
          >
            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "PC Automotive Services",
                "image": "https://pcautoimport.nl/static/51df9f887f730b1cc1b164eade33175b/497c6/logo.png",
                "@id": "",
                "url": "https://pcautoimport.nl/",
                "telephone": "+31 (0) 20 244 1586",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Swammerdamstraat 12B",
                  "addressLocality": "Amsterdam",
                  "postalCode": "1091 RT",
                  "addressCountry": "NL"
                }
              }
            `}</script>
          </Helmet>
          <Flex flexDirection="column" minHeight="100vh">
            <Nav />
            {mode === 'dark' && <Box pt={100} />}
            <Box flex="1 0 auto">{children}</Box>
            <Footer />
          </Flex>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default Layout
